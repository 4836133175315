import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { supabase } from '../supabaseClient'
import { Banner } from '../components/Banner'

export const LoginPage = () => {
  const authStyles = {
    theme: ThemeSupa,
    variables: {
      default: {
        colors: {
          brand: '#007bff',
          brandAccent: '#0056b3',
          brandButtonText: 'white',
        },
        space: {
          inputPadding: '12px',
          buttonPadding: '12px 16px',
        },
        radii: {
          borderRadiusButton: '6px',
          buttonBorderRadius: '6px',
          inputBorderRadius: '6px',
        },
      },
    },
    style: {
      container: {
        marginTop: '20px',
        width: '100%',
        maxWidth: '360px',
        margin: '0 auto',
      },
      button: {
        height: '48px',
        border: 'none',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        fontWeight: '500',
        transition: 'all 0.2s ease',
        width: '100%',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        },
      },
      anchor: {
        color: '#007bff',
        fontWeight: '500',
        '&:hover': { color: '#0056b3' },
      },
      divider: { margin: '24px 0' },
      label: {
        fontSize: '14px',
        color: '#333',
        marginBottom: '8px',
      },
      message: {
        padding: '12px',
        borderRadius: '6px',
        marginBottom: '16px',
        backgroundColor: '#f8f9fa',
      },
      loader: { color: '#007bff' },
    },
  }

  return (
    <>
      <Banner />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 60px)',
        padding: '20px',
        background: '#f8f9fa',
      }}>
        <div style={{
          width: '100%',
          maxWidth: '900px',
          padding: '32px',
          background: 'white',
          borderRadius: '12px',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.08)',
        }}>
          <h2 style={{
            textAlign: 'center',
            marginBottom: '24px',
            color: '#333',
            fontSize: '24px',
            fontWeight: '600',
          }}>
            Welcome to Flippy
          </h2>
          <img
            src="/flippy-hero.png"
            alt="Flippy Hero"
            style={{
              display: 'block',
              width: '100%',
              maxWidth: '700px',
              height: 'auto',
              margin: '0 auto 24px',
              borderRadius: '10px',
            }}
          />
          <p style={{
            textAlign: 'center',
            marginBottom: '16px',
            color: '#666',
            fontSize: '16px',
            fontWeight: '500',
          }}>
            Sign in / Sign up to continue to your account
          </p>

          <Auth
            supabaseClient={supabase}
            appearance={authStyles}
            providers={['google']}
            redirectTo={window.location.origin}
            view="sign_in"
            showLinks={false}
            onlyThirdPartyProviders
          />
        </div>
      </div>
    </>
  )
}
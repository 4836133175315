import { useState, useEffect, useRef } from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from './supabaseClient';
import { SuccessPage } from './pages/SuccessPage';
import { PricingPage } from './pages/PricingPage';
import { LoginPage } from './pages/LoginPage';
import './index.css'
import './App.css'


function App() {
    const [session, setSession] = useState<Session | null>(null);
    const initialSetupDone = useRef(false);

    useEffect(() => {
        // Fetch initial session
        const fetchSession = async () => {
            const { data: { session }, error } = await supabase.auth.getSession();
            if (error) {
                console.error('Error fetching session:', error);
            } else {
                console.log('Initial session loaded:', session?.user?.email);
                setSession(session);
            }
        };

        fetchSession();

        // Set up auth state change listener
        const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
            console.log("Auth state changed:", event, session?.user?.email);
            
            if (event === 'SIGNED_OUT') {
                // Clear session immediately on sign out
                setSession(null);
                // Clear any local storage
                localStorage.removeItem('supabase.auth.token');
                localStorage.removeItem('flippy.session');
            } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
                // Refresh the session data
                const { data: { session: freshSession }, error } = await supabase.auth.getSession();
                if (error) {
                    console.error('Error refreshing session:', error);
                } else {
                    console.log('Session refreshed:', freshSession?.user?.email);
                    setSession(freshSession);
                }
            }
        });

        // Cleanup subscription on unmount
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        const setupUser = async () => {
            // Skip folder creation if initial setup is done
            if (initialSetupDone.current) {
                return;
            }
            initialSetupDone.current = true;

            // Create or update user record
            const { error: userError } = await supabase
                .from('users')
                .upsert({
                    id: session?.user.id,
                    email: session?.user.email
                });

            if (userError) {
                console.error('Error inserting/updating user:', userError);
                return;
            }

            // Check if user already has any folders
            const { data: existingFolders } = await supabase
                .from('contents')
                .select('id')
                .eq('user_id', session?.user.id)
                .eq('is_folder', true)
                .limit(1);

            // Only create default folders if user has no folders yet
            if (!existingFolders?.length && session?.user?.id) {
                const now = new Date().toISOString();
                const defaultFolders = ['articles', 'blogs', 'highlights'].map(title => ({
                    user_id: session.user.id,
                    title,
                    is_folder: true,
                    source: 'web',
                    url: null,
                    parent_id: null,
                    created_at: now,
                    updated_at: now
                }));

                console.log('Attempting to create folders:', defaultFolders);
                const { data: createdFolders, error: foldersError } = await supabase
                    .from('contents')
                    .insert(defaultFolders)
                    .select();

                if (foldersError) {
                    console.error('Error creating default folders:', {
                        message: foldersError.message,
                        details: foldersError.details,
                        hint: foldersError.hint,
                        code: foldersError.code
                    });
                    return;
                }

                console.log('Successfully created folders:', createdFolders);

                // Add example content for each folder
                const exampleContent = createdFolders?.map(folder => {
                    let content;
                    switch (folder.title) {
                        case 'articles':
                            content = {
                                user_id: session.user.id,
                                title: 'The Art of Productivity: A Comprehensive Guide',
                                url: 'https://medium.com/swlh/the-art-of-productivity-a-comprehensive-guide-7a7f6f5e2de4',
                                is_folder: false,
                                source: 'web',
                                parent_id: folder.id,
                                created_at: now,
                                updated_at: now
                            };
                            break;
                        case 'blogs':
                            content = {
                                user_id: session.user.id,
                                title: 'The Pragmatic Engineer',
                                url: 'https://blog.pragmaticengineer.com/',
                                is_folder: false,
                                source: 'web',
                                parent_id: folder.id,
                                created_at: now,
                                updated_at: now
                            };
                            break;
                        case 'highlights':
                            content = {
                                user_id: session.user.id,
                                title: 'The Psychology of Flow: What Makes a Great Developer',
                                url: 'https://dev.to/devteam/the-psychology-of-flow-what-makes-a-great-developer-1e9k',
                                is_folder: false,
                                source: 'web',
                                parent_id: folder.id,
                                created_at: now,
                                updated_at: now
                            };
                            break;
                        default:
                            return null;
                    }
                    return content;
                }).filter(Boolean);

                if (exampleContent?.length) {
                    const { error: contentError } = await supabase
                        .from('contents')
                        .insert(exampleContent);

                    if (contentError) {
                        console.error('Error creating example content:', {
                            message: contentError.message,
                            details: contentError.details,
                            hint: contentError.hint,
                            code: contentError.code
                        });
                    } else {
                        console.log('Successfully created example content');
                    }
                }
            }
        };

        setupUser();
    }, [session]);

    if (!session) {
        return <LoginPage />;
    }

    if (window.location.pathname === '/success') {
        return <SuccessPage session={session} />;
    }

    return <PricingPage session={session} />;
}

export default App;

import { useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Session } from '@supabase/supabase-js';
import { Banner } from '../components/Banner';

interface SuccessPageProps {
  session: Session | null;
}

export const SuccessPage = ({ session }: SuccessPageProps) => {
  useEffect(() => {
    const updateUserToPro = async () => {
      if (!session?.user?.id) {
        console.log("No valid session found");
        return;
      }

      try {
        console.log("id:", session.user.id);
        const { error } = await supabase
          .from('users')
          .update({
            is_paying: true,
            purchased_plan: 'pro'
          })
          .eq('id', session.user.id)
          .select();

        if (error) {
          console.error('Error updating user:', error);
        } else {
          console.log("Successfully updated user plan to Pro");
        }
      } catch (error) {
        console.error('Error in plan update:', error);
      }
    };

    updateUserToPro();
  }, [session]);

  return (
    <>
      <Banner />
      <div className="success-container">
        <div className="success-card">
          <div className="success-icon">✓</div>
          <h1>Thank You!</h1>
          <p>Your subscription to Flippy Pro has been confirmed.</p>
          <div className="plan-details">
            <h2>Your Current Plan: Pro</h2>
            <ul>
              <li>✓ Everything in Free Plan</li>
              <li>✓ Randomized Tour</li>
              <li>✓ Timer Function</li>
            </ul>
          </div>
          <button
            className="return-button"
            onClick={() => window.location.href = '/'}
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    </>
  );
};

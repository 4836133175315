import { ASSETS } from '../constants';
import { supabase } from '../supabaseClient';
import { useState, useRef, useEffect } from 'react';

export const Banner = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogout = async () => {
    try {
      // Get current session before signing out
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        console.log('No active session found, proceeding with cleanup');
      }

      // First attempt to sign out from Supabase
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Supabase signOut error:', error);
        // If we get a session_not_found error, we can proceed with cleanup
        if (error.message !== 'Session from session_id claim in JWT does not exist') {
          throw error;
        }
      }

      console.log('Successfully signed out from Supabase');

      // Clear any local storage items related to auth
      try {
        localStorage.removeItem('supabase.auth.token');
        localStorage.removeItem('flippy.session');
        console.log('Cleared local storage items');
      } catch (storageError) {
        console.error('Error clearing localStorage:', storageError);
      }
      
      // Clear cookies by setting them to expire
      try {
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        console.log('Cleared cookies');
      } catch (cookieError) {
        console.error('Error clearing cookies:', cookieError);
      }

      // Close the dropdown
      setIsDropdownOpen(false);

      // Force reload the page to ensure clean state
      window.location.href = '/';
    } catch (error: any) {
      console.error('Error during sign out process:', error);
      // Show more specific error to user
      alert(`Failed to sign out: ${error?.message || 'Unknown error occurred'}. Please try again or refresh the page.`);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="flex justify-between items-center bg-white p-4 shadow fixed top-0 left-0 w-full z-50">
      {/* Left Section: Logo and Text */}
      <div className="flex items-center space-x-3">
        <img 
          src={ASSETS.LOGO} 
          alt="Flippy Logo" 
          className="h-10 w-10 object-contain"
        />
        <div className="flex items-center space-x-2">
          <span className="text-lg font-bold text-gray-800 italic">Flippy</span>
          <span className="text-sm text-gray-500 italic">Enjoy your saved content!</span>
        </div>
      </div>

      {/* Right Section: Product Hunt and User Icon */}
      <div className="flex items-center space-x-4">
        {/* Product Hunt Badge */}
        <a 
          href="https://www.producthunt.com/posts/flippy-3?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-flippy&#0045;3" 
          target="_blank" 
          rel="noopener noreferrer"
          className="transform scale-[0.6] origin-right"
        >
          <img 
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=744409&theme=light" 
            alt="Flippy - Product Hunt" 
            className="w-[250px] h-[54px]"
          />
        </a>

        {/* User Icon with Dropdown */}
        <div className="relative" ref={dropdownRef}>
          <button 
            className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <svg 
              className="w-5 h-5 text-gray-600"
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2"
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          </button>

          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1">
              <button
                onClick={handleLogout}
                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

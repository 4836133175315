import { Session } from '@supabase/supabase-js'
import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import { Banner } from '../components/Banner'
import './../index.css'
import './../App.css'

interface PricingPageProps {
  session: Session
}

export const PricingPage = ({ session }: PricingPageProps) => {
  const [loading, setLoading] = useState(false)
  const [currentPlan, setCurrentPlan] = useState<string | null>(null)

  useEffect(() => {
    const fetchUserPlan = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('purchased_plan')
        .eq('id', session.user.id)
        .single()

      if (error) {
        console.error('Error fetching user plan:', error)
        return
      }

      setCurrentPlan(data.purchased_plan)
    }

    fetchUserPlan()
  }, [session.user.id])

  const handleProUpgrade = async () => {
    try {
      setLoading(true)
      console.log('Starting upgrade process...')

      const { data, error } = await supabase.functions.invoke('flippy-stripe-checkout', {
        body: {
          userId: session.user.id,
          email: session.user.email,
          priceId: "price_1QaXXO1irze4EbJjCAyGZLCF",
        },
      })

      if (error) throw error
      if (!data.url) throw new Error('No checkout URL received')

      window.location.href = data.url
    } catch (error) {
      console.error('Error:', error)
      alert('Error processing upgrade request')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Banner />
      <div className="pricing-container">
        <div className="pricing-grid">
          {/* Free Plan */}
          <div className="pricing-card">
            <div className="pricing-header">
              <h2>Free Plan</h2>
              <div className="price">$0<span>/month</span></div>
            </div>
            <ul className="features">
              <li>
                <span className="feature-icon">✓</span>
                <span className="feature-text">
                  <strong>Surprise Discovery on Every Tab</strong>
                  <p>
                    Each new tab opens a random bookmark from the folder you pick,
                    giving you a fun way to rediscover saved sites.
                  </p>
                </span>
              </li>
              <li>
                <span className="feature-icon">✓</span>
                <span className="feature-text">
                  <strong>Interactive Bookmark Tour</strong>
                  <p>
                    Cycle through your saved content one by one. Just select a folder and
                    click, making it easy to catch up on everything you’ve saved.
                  </p>
                </span>
              </li>
            </ul>
          </div>

          {/* Pro Plan */}
          <div className={`pricing-card pro ${currentPlan === 'pro' ? 'current-plan' : ''}`}>
            <div className="pricing-header">
              <h2>Pro Plan</h2>
              {currentPlan === 'pro' && (
                <div className="current-plan-tag bg-blue-500 text-white text-xs font-medium px-3 py-1 rounded-full">
                  Current Plan
                </div>
              )}
              <div className="price">$9.99<span>/month</span></div>
            </div>
            <ul className="features">
              <li>
                <span className="feature-icon">✓</span>
                <span className="feature-text">
                  <strong>Everything in Free</strong>
                  <p>All features and perks from the Free Plan carry over.</p>
                </span>
              </li>
              <li>
                <span className="feature-icon">✨</span>
                <span className="feature-text">
                  <strong>Randomized Tour</strong>
                  <p>
                    Let the tour jump randomly from one bookmark to another for
                    a spontaneous exploration of your saved sites.
                  </p>
                </span>
              </li>
              <li>
                <span className="feature-icon">✨</span>
                <span className="feature-text">
                  <strong>Timer Function</strong>
                  <p>
                    Set a custom time interval for flipping through your saved content
                    automatically—perfect for a quick reading break or a morning routine.
                  </p>
                </span>
              </li>
            </ul>
            {currentPlan !== 'pro' && (
              <button
                className="plan-button upgrade-button"
                onClick={handleProUpgrade}
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Upgrade Now'}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
